<template>
    <div>
        <b-card-actions action-collapse title="Unite">
            <b-table
                ref="refPropertyListTable"
                :items="propertyData"
                responsive
                striped
                :fields="tableColumns_property"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                show-empty
                empty-text="No matching records found"
                class="position-relative"
            >
                <template slot="top-row" slot-scope="{ fields }">
                    <th v-for="field in fields" :key="'field_' + field.key">
                        <b-form-input
                            v-if="!['image', 'check_box'].includes(field.key)"
                            v-model="propertyFilters[field.key]"
                            :placeholder="field.label"
                        />
                    </th>
                </template>
                <template #cell(check_box)="data">
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="radio" 
                            id="flexRadioDefault"
                            :value="data.item.id"
                            :checked="selectedPropertyId === data.item.id"
                            @click="togglePropertySelection(data.item.id)"
                        />
                    </div>
                </template>
                <template #cell(image)="data">
                    <b-link v-if="data.item.folder_id == 0" variant="flat-primary" class="pb0" :to="{ name: 'property-upload', params: { id: data.item.id } }">
                        <b-img   
                            class="img_property" v-if="data.item.property_images[0]"
                            :src="data.item.property_images[0].fullfilepath"
                            fluid
                        />
                        <b-img   
                            class="img_property" v-else
                            :src="'https://findbangkokroom.com/public/img/storage/1698858264911-picture.png'"
                            fluid
                        />
                    </b-link>
                    <b-badge v-else variant="flat-primary" class="pb0" >
                        <b-img   
                            class="img_property" 
                            v-if="data.item.property_images[0]"
                            :src="data.item.property_images[0].fullfilepath"
                            fluid
                        />
                        <b-img   
                            class="img_property" v-else
                            :src="'https://findbangkokroom.com/public/img/storage/1698858264911-picture.png'"
                            fluid
                        />
                    </b-badge>
                    </template>
                <template #cell(unit)="data">
                    {{ data.item.unit }} 
                    {{ data.item.address_no }}
                </template>
                <template #cell(property)="data">
                    <div class="list-property">
                        <router-link :to="{ name: 'property-edit', params: { id: data.item.id } }" >{{ data.item.property_code }}</router-link>
                        <b-badge v-if="data.item.action_id == 1" variant="light-secondary">
                            {{ data.item.action_name }}
                        </b-badge>
                        <b-badge v-if="data.item.action_id == 2" variant="light-info">
                            {{ data.item.action_name }}
                        </b-badge>
                        <b-badge v-if="data.item.action_id == 3" variant="light-success">
                            {{ data.item.action_name }}
                        </b-badge>
                        <b-badge v-if="data.item.action_id == 4" variant="light-warning">
                            {{ data.item.action_name }}
                        </b-badge>
                        <b-badge v-if="data.item.status_id == 1" variant="light-success">
                            {{ data.item.status_code }}
                        </b-badge>
                        <b-badge v-else variant="light-secondary">
                            {{ data.item.status_code }}
                        </b-badge>
                        <br />
                        {{ data.item.place_name }}  <router-link :to="{ name: 'matched-property', params: { code: data.item.property_code } }" >Matched</router-link><br />
                        {{ formatDate(data.item.availble_date, {  day: 'numeric', month: 'short', year: 'numeric' }) }}
                        <br />
                        <p v-if="data.item.comments[0]" class="text-truncate mb-0 text-warning width-200" >{{ filterTags(data.item.comments[0].comment) }}</p>
                    </div>
                </template>
            </b-table>
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        :to="{ name: 'inventory-add', query: { propertyId: setSelectPropertyId } }"
                    >
                        <span>Next</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-actions>
    </div>
</template>

<script>
    import {
        BFormInput, BFormGroup, BForm, BRow, BCol, BTable, BLink, BImg, BButton, BBadge,
        BContainer, BCard, BFormDatepicker, BMedia, BMediaAside, BMediaBody, BFormFile,
        BCardText,
    } from 'bootstrap-vue';
    import Ripple from "vue-ripple-directive";
    import propertiesStoreModule from '../../properties/propertiesStoreModule';
    import { ref, onMounted, computed, watch, onUnmounted, reactive } from '@vue/composition-api';
    import store from '@/store';
    import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import draggable from "vuedraggable";
    import { filterTags, formatDate } from '@core/utils/filter'
    import { debounce } from "lodash";
    import router from '@/router'

    export default {
        components: {
            BCardActions,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BTable,
            ValidationProvider,
            ValidationObserver,
            BLink,
            BImg,
            BButton,
            BBadge,
            draggable,
            BContainer,
            BCard,
            BFormDatepicker,
            BMedia,
            BMediaAside,
            BMediaBody,
            BFormFile,
            BCardText,
        },
        directives: {
            Ripple,
        },
        setup() {
            const PROPERTY_APP_STORE_MODULE_NAME = "app-property";
            if (!store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) {
                store.registerModule(PROPERTY_APP_STORE_MODULE_NAME, propertiesStoreModule);
            }

            onUnmounted(() => {
                if (store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) {
                    store.unregisterModule(PROPERTY_APP_STORE_MODULE_NAME);
                }
            });

            const tableColumns_property = [
                { key: "check_box", label: ""},
                { key: "image", label: "Image" },
                { key: "property", label: "Property" },
                { key: "owner", label: "Owner" },
                { key: "unit", label: "House No Units" },
                { key: "floors", label: "Floors" },
                { key: "type_name", label: "Type" },
                { key: "building", label: "Building" },
                { key: "bedroom", label: "BR" },
                { key: "sqm", label: "Sqm" },
            ];

            const currentPage = ref(1);
            const perPage = ref(10);
            const sortBy = ref('id');
            const isSortDirDesc = ref(true);
            const propertyData = ref([])
            const isRoomModalActive = ref(false)
            const roomType = ref({})
            const selectedPropertyId = ref(null)
            const setSelectPropertyId = ref(null)
            const selectedPropertyData = ref(null)
            // const toast = useToast();

            const propertyFilters = ref({
                unit: '',
                propertyfilter: '',
                ownerfilter: '',
                floors: '',
                type_name: '',
                building: '',
                bedroom: '',
                sqm: '',
            });

            async function fetchPropertieData (ctx, callback) {
                const filterPayload = {
                    ...propertyFilters.value,
                    offset: (currentPage.value - 1) * perPage.value,
                    perpage: perPage.value,
                    sort_by: sortBy.value,
                    sort_desc: isSortDirDesc.value,
                };
        
                store
                    .dispatch('app-property/fetchProperties', filterPayload)
                    .then((response) => {
                        propertyData.value = response.data.data
                    });
            };

            const debouncedFetchPropertieData = debounce(fetchPropertieData, 300);
  
            watch( propertyFilters, () => {
                    debouncedFetchPropertieData();
                },
                { deep: true }
            );

            const togglePropertySelection = (id) => {
                selectedPropertyId.value = selectedPropertyId.value === id? null: id
                setSelectPropertyId.value = id
            }

            const setRoute = () => {
                let queryURL = {}

                if (setSelectPropertyId.value) queryURL.id = setSelectPropertyId.value;

                const currentQuery = router.currentRoute.query;
                const newQueryString = new URLSearchParams(queryURL).toString();

                if (newQueryString !== new URLSearchParams(currentQuery).toString()) {
                    router.replace({ query: queryURL });
                }
            }

            onMounted(() => {
                fetchPropertieData()
            })

            return {
                propertyData,
                tableColumns_property,
                sortBy,
                fetchPropertieData,
                isSortDirDesc,
                propertyFilters,
                selectedPropertyId,
                formatDate,
                filterTags,
                togglePropertySelection,
                setSelectPropertyId,
                setRoute,
            }
        }
    }
</script>